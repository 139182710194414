import React from 'react';
import { splitString, useTranslate } from '@modules';
import { Paragraph, SubTitle } from '@themed';
import { contentStyles } from './SupportContent.styles';

interface SupportContentProps {
  title?: string;
  scope?: string | null;
}

export const SupportContent = ({ title = 'common.finnoconsultSupport', scope }: SupportContentProps) => {
  if (!scope) return null;
  const __ = useTranslate();

  const list = splitString(scope);

  return (
    <React.Fragment>
      <SubTitle sx={contentStyles.subtitle}>{__(title)}</SubTitle>
      <Paragraph sx={contentStyles.support}>
        <ul>
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </Paragraph>
    </React.Fragment>
  );
};
