import React from 'react';
import { Box } from '@themed';
import { CompactSection, ParallaxImage } from '@core';
import { useTranslate } from '@modules';
import { styles } from './Init.styles';

export const Init = () => {
  const sectionRef = React.useRef(null);
  const backgroundRef = React.useRef<HTMLDivElement>(null);
  const __ = useTranslate();

  // {/* TODO: BlogHeader animation? */}

  return (
    <CompactSection
      titleAs='h1'
      ref={sectionRef}
      id='casestudies.init'
      header={() => <Box sx={styles.background} ref={backgroundRef} />}
      sx={styles.init}
    >
      <ParallaxImage src='team_04.jpg' alt={__('pages.casestudies.init.parallaxImgAlt')} sx={styles.image} />
    </CompactSection>
  );
};
