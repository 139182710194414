import React from 'react';
import { Link } from 'gatsby';
import { SectionWithContent } from '@core';
import { useLocale, useTranslate } from '@modules';
import { Button } from '@themed';
import { useReferences } from '../workshops/useReferences';
import { Reference } from './Reference';
import { SupportContent } from './SupportContent';

export const reference = {
  aside: {
    transform: 'none',
  },
};

interface ReferenceListProps {
  type: 'rapid' | 'finno';
  link?: boolean;
}

export const ReferenceList = ({ type, link }: ReferenceListProps) => {
  const locale = useLocale();
  const __ = useTranslate();
  const references = useReferences(type);

  // TODO: formatImage

  return (
    <SectionWithContent data-cy='casestudies-referencelist'>
      {references &&
        references.map((node) => (
          <Reference key={node.slug} {...node} sx={reference}>
            <SupportContent scope={node.supportScope} />
            {link && (
              <Link to={`/${locale}/case-study/${node.slug}`}>
                <Button sx={{ variant: 'buttons.cta', mt: 5 }}>{__('pages.casestudies.cases.button')}</Button>
              </Link>
            )}
          </Reference>
        ))}
    </SectionWithContent>
  );
};
