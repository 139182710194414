/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO, Image } from '@core';
import { Init } from 'src/components/composite/casestudies/Init';
import Divider from 'src/components/composite/casestudies/Divider';
import { contactIconStyles, ContactSection } from '@composite';
import { __ } from '@modules';
import { ReferenceList } from '../components/composite/rapid/ReferenceList';

export default () => (
  <Layout invertedHeader>
    <StaticSEO pageId='services' />
    <Init />
    <ReferenceList type='finno' link />
    <Divider text={__('pages.casestudies.divider.paragraph')} />
    <ReferenceList type='rapid' />
    <ContactSection id='rapid.contact'>
      <Image src='/images/pacman-white_2x.gif' alt='pacman animation' sx={contactIconStyles} />
    </ContactSection>
  </Layout>
);
